.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.preloader img {
  width: 150px;
}
.preloader h1 {
  font-family: "Remachine Script Personal Use" !important;
  color: white;
  font-size: 60px;
}

@media (max-width: 480px) {
  .preloader img {
    width: 150px;
  }
}/*# sourceMappingURL=Loading.css.map */