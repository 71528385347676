.header_area {
    padding: 10px 0;

    .logo {
        display: flex;
        align-items: center;
        gap: 16px;
        width: max-content;
        height: max-content;
        position: relative;
        color: #e5e5e5;
        transition: .1s ease;
        z-index: 351;
        cursor: pointer;
        text-decoration: none;
        overflow: hidden;

        h3 {
            margin: 0;
        }

        img {
            width: 40px;
        }
    }

    .navbar {
        margin: 0;
        padding: 0;

        .menu {
            display: flex;
            align-items: center;
            gap: 49px;
            margin: 0;
            padding: 0;

            li {
                a {
                    font-size: 16px;
                    color: var(--text-main-color);
                    transition: .3s;
                    display: block;
                    padding: 26px 0px;
                    font-weight: 400;
                    text-decoration: none;

                    &:hover {
                        color: var(--white-color);
                    }

                    &.active {
                        color: var(--white-color);
                    }
                }
            }
        }

        .theme_btn {
            display: none;
        }
    }

    .show_menu {
        display: none;
        color: #fff;
        font-size: 25px;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 16;
        border-radius: 50%;

        span {
            width: 20px;
            height: 2px;
            background: #fff;
            display: block;
            transition: .4s;
            border-radius: 1px;

            &:last-child {
                transform-origin: left;
                margin-top: 5px;
            }
        }

        &.active {
            span {
                &:first-child {
                    transform: translate3d(0.5px, 3px, 0px) scale(1, 1) rotate(45deg) scale(1, 1);
                }
            }

            span {
                &:last-child {
                    transform: translate3d(3.5px, 3px, 0px) scale(1, 1) rotate(-45deg) scale(1, 1);
                }
            }
        }

    }
}

@media (max-width:991px) {
    .header_area .logo {
        font-size: 20px;
    }
}

@media (max-width:767px) {
    .header_area {
        padding: 20px 0;

        .navbar {
            position: absolute;
            width: 100%;
            height: 100vh;
            background: #0F0F0F;
            z-index: 15;
            overflow-x: hidden;
            transform-origin: 50% 50% 0px;
            top: 0;
            left: 0;
            padding-top: 100px;
            padding-bottom: 24px;
            justify-content: center;
            opacity: 0;
            visibility: hidden;
            will-change: transform;
            transform: translate3d(0px, -300px, 0px) scale(1, 1) scale(1, 0.945615);
            // transform: translate3d(0px, -300px, 0px) scale(1, 0.5) scale(1, 0.5);
            transition: .5s;

            .theme_btn {
                display: inline-block !important;
            }

            .menu {
                flex-direction: column;
                justify-content: center;
                text-align: center;
                align-items: center;
                width: 100%;
                gap: 30px;

                li {
                    a {
                        padding: 0;
                    }
                }
            }

            &.active {
                opacity: 1;
                visibility: visible;
                transform: translate3d(0px, 0, 0px) scale(1, 1) scale(1, 1);

            }
        }

        .show_menu {
            display: flex;
        }

        .gx-row {
            .theme_btn {
                display: none;
            }
        }
    }
}
